import React from 'react'
import { Link } from 'gatsby'

import { Layout, SEO } from '../components/common'
// import { MetaData } from '../components/common/meta'

const SubscribeSuccessPage = () => (
    <React.Fragment>
        <SEO title='Subscribe Success' description='Great success' dontIndex={true}/>
        <Layout>
            <div className="container">
                <article className="content">
                    <h1 className="content-title">Thanks for subscribing!</h1>
                    <p>Now please go <b>check your email</b> and click the confirmation link.</p>
                    <p>Annoying, I know. But I need to make sure the email address is legit, and then I'll keep you updated on new writings and quantified self analysis tools.</p>
                    <p>I'll send you no more than 2-3 emails a month with some interesting content, but if you ever want to unsubscribe, there's a link in the email you just received.</p>
                    <p>Until the next one,<br/>
                        - Fed
                    </p>
                </article>
            </div>
        </Layout>
    </React.Fragment>
)

export default SubscribeSuccessPage
